
footer {
    position: relative;
    background-color: #242424;
    display: flex;
    padding: 6% 0;
}

.footer__row {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer__logo--img {
    width: 80px;
}

.footer__social--list {
    width: 100%;
    max-width: 500px;
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
}

.footer__social--link, 
.footer__copyright {
    color: white;
}