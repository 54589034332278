*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}
html {
    scroll-behavior: smooth;
}

nav,
scroll,
header {
    opacity: 1;
    visibility: visible;
    transition: all 600ms 800ms;
}

.modal--open .nav,
.modal--open .scroll,
.modal--open .header {
    opacity: 0;
    visibility: hidden;
    transition: all 400ms;
}


li{
    list-style: none;
}

section{
    background-color: rgba(175, 200, 220, 0.38);
    transition: all 300ms ease;
}

.click {
    transition:all 300ms ease;
    cursor: pointer;
}

.click:hover {
    transform: scale(1.1);
}

.click:active {
    transform: scale(0.8);
}

p{
    line-height:1.5 ;
    font-size: 16px;
}

a{
    text-decoration: none;
}

button {
    cursor: pointer;
}

.container{
    display: flex;
    padding: 50px 0;
    width: 100%;
}

.row {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 12px;
    
}
.link__hover-effect {
    position: relative;
}
.link__hover-effect::after {
    content:"";
    position: absolute;
    bottom: -3px;
    height: 3px;
    width: 0;
    right: 0;
    transition: all 300ms ease;
}
.link__hover-effect--black:after {
    background-color: #242424;
}
.link__hover-effect--white:after {
    background-color: #fff;
}

.link__hover-effect:hover:after {
    left: 0;
    width: 100%;
}

/* contact side */

input, textarea {
    width: 100%;
    background-color:  rgb(16, 79, 130);
    color: white;
    outline: none;
    border: 3px solid #c0c4cc;
    border-top: none;
    border-left: none;
    border-right: none;
    height: 40px;
    transition: all 300ms ease;
}

label {
    font-size: 14px;
    font-weight: bold;
}

input:hover, textarea:hover {
    border-color: #dcdfe6;
}

.input:focus, textarea:focus {
    border-color: #f06449;
}

textarea {
    resize: vertical;
    height: 120px;
    margin-top: 6px;
    
}



