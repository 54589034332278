.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 1100px;
    height: 700px;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    visibility: hidden;
    z-index: -1;
    transition: visibility 1s, z-index 1s, box-shadow 1s ease;
}

.modal__half {
    width: 50%;
    padding: 40px 72px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    transition: all 300ms ease-in;
}

.modal_about {
    background-color: rgb(245, 245, 245);
    transform: translateX(-110%);
}

.modal_contact {
    transform: translateX(110%);
}

.modal--open .modal{
    z-index:  60;
    box-shadow: 0 20px 80px 0 rgb(0, 0, 0.55);
    visibility: visible;
}

.modal--open .modal_about ,
.modal--open .modal_contact 
{
    transform: translateX(0%);
}


.modal__languages {
    display: flex;
    flex-wrap: wrap;
}

.modal__language {
    width: 25%;
    padding: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 300ms ease;
}

.modal__language:hover .langauge__name {
    transform: scale(1);
}

.modal__language:hover  {
    filter: brightness(70%);
    transform: scale(0.8);
}

.langauge__name {
    position: absolute;
    bottom: -10px;
    transform: scale(0);
    transition: all 300ms ease;
}

.modal__language--img {
    width: 100%;
}

.modal__title {
    font-size: 26px;
}

.modal__sub-title {
    margin: 12px 0 24px 0;
    font-size: 14px;
}

.modal__para {
    margin-bottom: 12px;
    line-height: 1.75;
}


.blue-text {
    color: blue;
}


.modal_contact {
    background-color: rgb(16, 79, 130);
    color: white;
}

.form__item {
    margin-bottom: 20px;
}

.form__submit {
    background-color: #f06449;
    border: 2px solid #f06449;
    color: white;
    font-weight: 700;
    max-width: 240px;
    width: 100%;
    padding: 12px 24px;
    font-size: 20px;
    transition: all 300ms ease;
}

.form__submit:hover {
    border-color: #fff;
    background-color: transparent;
}

.form__submit:active {
    border-color: #f06449;
    color: #f06449;
}

.modal__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    z-index: -1;
}

.modal__overlay--loading {
    background-color: grey;
    font-size: 50px;
    z-index: -1;
}

.fa-spinner {
    animation: spinner 1200ms infinite;
}
@keyframes spinner {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}


.modal__overlay--visible {
    z-index: 1;
    display: flex;
}


.modal__overlay--success {
    background-color: #242424;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    padding: 28px;
}

.modal__exit {
    color: white;
    position:absolute;
    top: 30px;
    right: 40px;
    font-size: 32px;
    z-index: 100;
}


.dark-theme .title,
.dark-theme .section__title,
.dark-theme .sub__title,
.dark-theme .nav__link--anchor,
.dark-theme .fa-adjust,
.dark-theme .header__para
{
    color: #fff;
}

.dark-theme nav,
.dark-theme {
    background-color: rgba(0, 0, 0, 0.835);
}

.dark-theme section {
    background-color: rgba(0, 0, 0, 0.835);
}

.dark-theme #personal-logo {
    filter: none;
}

.dark-theme .scroll__icon {
    border-color: white;
}

.dark-theme .scroll__icon:after ,
.dark-theme .link__hover-effect--black::after {
    background-color: white;
}

.dark-theme {
    box-shadow: white;
}

.dark-theme .mail__btn {
    background-color: white;
    color: #242424;
}


@media (max-width: 768px){
.modal {
    top: 0;
    left: 0;
    height: auto;
    transform: none;
    width: 100%;
    flex-direction: column-reverse; 
    border-radius: 0;
 }

 .modal__half {
    width: 100%;
 }

}