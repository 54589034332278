#landing-page{
    min-height: 100vh;
}

.header{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: calc(100vh - 100px);
    padding:0 30px;
    padding-bottom: 150px;

}


.header__para{
     font-size: 25px;
     line-height: 2;
     max-width: 600px;
}


.social__list{
    margin-top: 16px;
    display: flex;
}

.social__link {
    background-color: #004581;
    color: white;
    padding: 6px;
    width: 32px;
    height: 32px;
    font-size: 14px;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.title {
     font-size: 110px;
     margin-bottom: 12px;
     text-align: left;
     line-height: 1;
}
.title--secondary,
.secondary {
    color: #004581 !important;
}


.mail__btn {
    width: 70px;
    height: 70px;
    font-size: 32px;
    border-radius: 50%;
    border: none;
    background-color: #004581;
    color: white;
    position: fixed;
    z-index: 100;
    bottom: 32px;
    right: 40px;
    box-shadow: 0px 8px 24px 2px rgba(49, 59, 169, 0.3);
}

.scroll {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);

}

.scroll__icon {
    width: 20px;
    height: 30px;
    border: 2px solid #004581;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.scroll__icon::after {
    content: "";
    width: 4px;
    height: 6px;
    background-color: #004581;
    border-radius: 2px;
    animation: scroll 1.7s infinite alternate-reverse;
}

@keyframes scroll {
    0% {
        transform: translateY(4px);
    }

    100% {
        transform: translateY(-4px);
    }
}


@media (max-width: 768px) {
    .title{
        font-size: 72px;

    }
    .header__para{
        font-size: 22px;
    }
}

@media (max-width: 480px){
    .title {
        font-size: 38px;
    }
    .header__para {
        font-size: 12px;
    }
    .nav__link:first-child{
        display: none;
    }
}
