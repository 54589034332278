.highlight__color{
    color: #004581;
}
.section__title {
    text-align: center;
    font-size: 48px;
    margin-bottom: 60px;
}

.project {
    margin: 60px;
}

.project__img {
    width: 100%;
    height: 700px;
    transition: all 600ms ease;
}

.project__wrapper {
    position: relative;
    height: 700px;
    border-radius: 20px;
    box-shadow: 0 20px 40px 0 rgb(0, 0, 0.45);
    overflow: hidden;
}

/* HOVER EFFECT */
    .project__wrapper:hover .project__description {
        opacity: 1;
        transform: translateY(-50%);
    }

    .project__wrapper:hover .project__img {
        transform: scale(1.09);
        filter: blur(9px);

    }
/*END  HOVER EFFECT */

.project__description {
    position: absolute;
    top: 50%;
    left: 50px;
    transform: translateY(100%);
    opacity: 0;
    transition: opacity 300ms, transform 450ms ease;
    color: white;
    max-width: 550px;
}

.project__wrapper:hover .project__wrapper--bg {
    opacity: 0.7;
}

.project__wrapper--bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1c1d1c;
    opacity: 0;
    transition: all 450ms ease;
}
.project__description--title {
    font-size: 40px;
}

.project__description--sub {
    font-size: 20px;
    margin-top: 8px;

}

.project__description--link {
    color: white;
    font-size: 18px;
    margin-right: 16px;
}

.project__description--para {
    margin: 6px 0;

}

/* 480px */
@media (max-width: 480px) {
    .section__title{
        font-size: 30px;
    }
    .project__description--title{
        font-size: 30px;
    }
    .project__description--para{
        display:none;
    }

    .project__description {
        left: 0;
        padding: 13px;
        width: 95%;
        text-align: center;
    }

    .project__description--sub{
        margin-bottom: 40px;
    }
}
/* 800px */
@media (max-width: 800px) {
    .project {
        padding: 20px 0;
        
        margin: 10px;
    }
    .project__wrapper{
        height: 400px;
        box-shadow: 0 0 12px 0;
    }
    .project__img {
        width: 100%;
        height: 400px;
        transition: all 100ms ease;
    }
}