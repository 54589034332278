#personal-logo{
    width: 50px;
    height: 50px;
    filter: invert(1);
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    height: 100px;
    background-color: rgba(175, 200, 220, 0.38);
    
}

.nav__link--list {
    display: flex;
}

.nav__link {
    margin: 0 12px;
}

.nav__link--anchor {
    text-decoration: none;
    font-size: 16px;
    color: black;
    font-weight: 600;
}

.fa-adjust {
    font-size: 20px;
}